import React, { useState, FC } from 'react'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import { Text, Box, Icon } from '@chakra-ui/react'
import RadioGroup from './RadioGroup'
import StarRadio from './StarRadio'
import Button from './Button'

import ErrorMessage from './Form/ErrorMessage/ErrorMessage'
import FormLabel from './Form/FormLabel'
import Select from './Select'

import { FaStar } from 'react-icons/fa'
import Step from './Step'
import getUrlParam from '../utils/getUrlParam'

export interface StarRatingFormProps {
  onSubmit: any,
  options: any,
  data: any,
  setCurrentStep: any,
  stepColors: any,
  title: string,
}

const StarRatingForm:FC<StarRatingFormProps> = ({ data, onSubmit, options, setCurrentStep, stepColors, title }) => {

  const [numberOfStars, setNumberOfStars] = useState('')

  const handleStarClick = (value: any) => {
    setNumberOfStars(value)
  }

  const selectedOptionProperty = (value: string, property: string) => {
    const option = options?.find((option: any) => option?.value === value)
    return option[property]
  }

  const FiveStarReview = () => {
    const handleClick = () => {
      setCurrentStep((prev: number) => prev + 2)
    }

    return (
      <>                
        <Text 
          align='center'
          maxW='384px'
          fontSize={['16px', '20px', '20px']}
          lineHeight={['22px', '27px', '27px']}
          mb={['10', '12', '12']}
        >
          That’s great to hear! Could you share this feedback on the App Store for other merchants to see?
        </Text>
        <Button 
          as='a' 
          href={selectedOptionProperty(data?.app_name, 'link')}
          target='_blank' 
          mb='4'
          onClick={handleClick} 
        >
          Share Feedback
        </Button>
        <Button 
          variant='link'
          fontWeight='300'
          onClick={handleClick}
          style={{
            textDecoration: 'underline',
          }}
        >
          No thanks
        </Button>
      </> 
    )
  }

  // If the app_name parameter value is not found in the options array,
  // then the app_name is not in the list of apps that have been reviewed.
  // So we show the form with a select for the visitor to select the app they want to review.
  const appNameProvided = getUrlParam('app_name') && options?.find((option: any) => option?.value === getUrlParam('app_name'))
  let validationSchema
  let initialValues
  if(appNameProvided) {
    validationSchema = Yup.object({
      stars: Yup.string().required()
    })
    initialValues = {
      stars: ''
    }
  } else {
    validationSchema = Yup.object({
      app_name: Yup.string().required(),
      stars: Yup.string().required()
    })
    initialValues = {
      app_name: '',
      stars: ''
    }
  }

  return (
    <Step
      stepColors={stepColors} 
      title={title}
    >      
      <Formik
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={onSubmit}
      >
        {({ isSubmitting }) => {
          return (
          <Form style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>   
            <FormLabel
                htmlFor='app_name'
                textAlign='center'
                marginBottom={[5]}
              >
              Providing feedback for:
            </FormLabel>
            <Box mx='auto' marginBottom={[10]} w='302px'>                    
            {appNameProvided ? (
                data?.app_name && (
                <Text
                  textAlign='center'
                  fontSize='22px'
                  fontWeight='700'
                  mb={[5]}
                >
                  {selectedOptionProperty(data?.app_name, 'label')}
                </Text>
                )
            ) : (      
              <>
                <Field name='app_name'>
                {({ field, form }: { field: any, form: any, }) => (
                <Select 
                  isDisabled={isSubmitting}
                  id='app_name'
                  name={field.name}
                  placeholder='Select app to review...'
                  options={options}
                  value={
                    options && options.find((option: { value: any }) => option.value === field.value)
                    // options
                    //   ? options.find((option) => option.value === field.value)
                    //   : ''
                  }
                  onChange={(option: { value: any }) =>
                    form.setFieldValue(field.name, option?.value)
                  }
                  onBlur={field.onBlur}
                />
                )}
                </Field>
                <ErrorMessage name='app_name' errorMsg='Please select the app you wish to rate.' />
              </>            
            )}
            </Box>
            <Box marginBottom={[10]}>
            {!isSubmitting && <FormLabel
              htmlFor='stars'
              textAlign='center'
            >
              How would you rate your experience with this app?
            </FormLabel>}
            <RadioGroup mx='auto' name='stars' display='flex' justifyContent='center' alignItems='center' gridColumnGap={2}>
              {Array.from(Array(5).keys()).map((_, key) => {
                const radioValue = key + 1
        
                return (
                  isSubmitting ? (
                    <Icon 
                      as={FaStar}
                      key={key}
                      boxSize='8'
                      cursor={'not-allowed'}
                      color={numberOfStars >= radioValue.toString() ? 'green.500' :'#E5E5E5'}
                    />
                  ) : (
                    <StarRadio
                      isDisabled={isSubmitting}
                      name='stars'
                      key={key}
                      value={radioValue}
                      numberOfStars={numberOfStars}
                      onClick={() => handleStarClick(radioValue)}
                    />
                  )
                )
              })}
            </RadioGroup>
            <ErrorMessage name='stars' errorMsg='Please rate the app from 1 to 5 stars.' />
          </Box>
          <Button mb='4' hidden={isSubmitting} type='submit'>Continue</Button>
          {Number(data.stars) === 5 ? <FiveStarReview /> : null}
        </Form>
      )}}
      </Formik>
    </Step>
  )
}

export default StarRatingForm
