import React, { FC } from 'react'
import { Select as ChakraSelect } from 'chakra-react-select'

interface SelectProps {
  isDisabled: boolean,
  id: string,
  name: string,
  placeholder: string,
  options: any,
  value: any,
  onChange: any,
  onBlur: any,
}

const Select:FC<SelectProps> = (props) => {
  return (
      <ChakraSelect
        {...props}
        chakraStyles={{
              control: (provided) => ({
                ...provided, // inherit default styles from select
            borderRadius: '34px',
            maxW: '100%',
          }),
          dropdownIndicator: (provided) => ({
            ...provided,
            bg: 'transparent',
            px: 2,
            cursor: 'inherit',
          }),
          indicatorSeparator: (provided) => ({
            ...provided,
            display: 'none',
          })
        }}
      />
  )
}

export default Select
