import React, { FC } from 'react'
import { Container } from '@chakra-ui/react'

export interface FormContainerProps {
  children: any,
  bg?: string,
  props?: any,
  mb?: any,
}

const FormContainer:FC<FormContainerProps> = ({ children, bg }, props) => {
  return ( 
    <Container
      as='section'
      centerContent
      bg={bg}
      px={[10, 10, 4, 4]}
      {...props}
    >
      {children}
    </Container>
  )
}

FormContainer.defaultProps = {
  bg: 'transparent',
}

export default FormContainer
