import React, { useEffect, useState, FC } from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { Container, Text, Box, Icon } from '@chakra-ui/react'
import Textarea from './Textarea'
import Button from './Button'
import Footer from './Layout/Footer'
import Step from './Step'

import getUrlParam from '../utils/getUrlParam'
import { MdPhone } from 'react-icons/md'

import axios from 'axios'
import StarRatingForm from './StarRatingForm'

export interface SteppedFormProps {}

const SteppedForm:FC<SteppedFormProps> = () => {

  const [data, setData] = useState({
    domain: '',
    app_name: '',
    stars: '',
    answer: '',
    agent: '',
    src: '',
  })
  const [currentStep, setCurrentStep] = useState(0)

  const options = [
    {
      label: 'Sky Pilot',
      value: 'sp',
      link: 'https://apps.shopify.com/sky-pilot#modal-show=ReviewListingModal'
    },
    {
      label: 'Order Tagger',
      value: 'ot',
      link: 'https://apps.shopify.com/ordertagger#modal-show=ReviewListingModal'
    },
    {
      label: 'Customer Tagger',
      value: 'ct',
      link: 'https://apps.shopify.com/customer-tagger#modal-show=ReviewListingModal'
    },
    {
      label: 'Shipping Rate Calculator Plus',
      value: 'srcp',
      link: 'https://apps.shopify.com/shipping-rates-calculator-plus#modal-show=ReviewListingModal'
    },
    {
      label: 'Video Background',
      value: 'vb',
      link: 'https://apps.shopify.com/video-background#modal-show=ReviewListingModal'
    },
    {
      label: 'Conjured Referrals',
      value: 'ref',
      link: 'https://apps.shopify.com/referral-program#modal-show=ReviewListingModal'
    }
  ]

  const stepColors = [
    { 
      footerBgColor: '#E3EEEA',
      bodyBgColor: 'white',
      textColor: 'green.500',
    },
    { 
      footerBgColor: '#E3EEEA',
      bodyBgColor: '#729A8C',
      textColor: 'white',
    },
    { 
      footerBgColor: 'white',
      bodyBgColor: '#E3EEEA',
      textColor: 'green.500',
    },
  ]

  const makeRequest = (formData: any) => {
    const { app_name, answer } = formData
    const { domain, src } = data

    axios({
      method: 'post',
      url: `${process.env.REACT_APP_ADD_APP_FEEDBACK_URL}?domain=${domain}&app_name=${app_name}&answer=${answer}&src=${src}`,
      data: {
        domain,
        app_name,
        answer,
        src
      }
    })
    .then((response) => {
      console.log(response.data)
    })
    .catch((error) => {
      console.log(error)
    })
  }

  const handleNextStep = (newData: { stars: string; app_name: string; domain: string; answer: string; agent: string }, final = false) => {
    setData((prev) => ({ ...prev, ...newData }))

    if (final) {
      makeRequest(newData)
    }

    setCurrentStep((prev) => prev + 1)
  }

  const handleStarRatingSubmition = (newData: any) => {
    const mergedData = {
      ...data,
      ...newData
    }

    const { stars, app_name, agent, domain, src } = mergedData
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_ADD_APP_RATTING_URL}?domain=${domain}&app_name=${app_name}&stars=${stars}&agent=${agent}&src=${src}`,
      data: {
        domain: domain,
        app_name: app_name,
        stars: stars,
        agent: agent,
        src: src,
      }
    })
    .then((response) => {
      console.log(response.data)
    })
    .catch((error) => {
      console.log(error)
    })

    if (Number(mergedData.stars) === 5) {
      setData((prev) => {
        return { ...prev, ...mergedData }
      })
    } else {
      handleNextStep(mergedData, false)
    }
  }

  const handleSubmit = (values: { domain: string; app_name: string; stars: string; answer: string; agent: string }) => {
    handleNextStep(values, true);
  };
  
  const renderStep = (currentStep: number, stepColors: any) => {
    switch (currentStep) {
      case 0:
        return (
            <StarRatingForm
              stepColors={stepColors[currentStep]} 
              title='Thank you for taking the time to provide us with feedback on your experience using one of our apps, or interacting with our support team.'
              onSubmit={handleStarRatingSubmition} 
              options={options} 
              data={data} 
              setCurrentStep={setCurrentStep} 
            />
          )
      case 1:
        return (
          <Step
            stepColors={stepColors[currentStep]} 
            title='Please describe your experience using this app'
          >
            <Formik
              validationSchema={Yup.object({
                answer: Yup.string().required().label('Answer'),
              })}
              initialValues={data}
              onSubmit={handleSubmit}
            >
            {({ values }) => (
              <Form style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>          
                  <Text
                    color={stepColors[currentStep].textColor}
                    fontStyle='normal'
                    fontWeight='400'
                    fontSize={['16px', '16px', '20px', '20px']}
                    lineHeight='27px'
                    align='center'
                    mb={10}
                  >
                    Are there any features or functionality you would like to see, or think are missing?
                  </Text>
                  <Textarea 
                    name='answer'
                    errorMsg='Please describe your experience using this app.'
                  />
                  <Button mt={8} type='submit'>Submit</Button>
                  <Button 
                          onClick={() => setCurrentStep((prev) => prev + 1)} 
                          variant='link'
                          fontWeight='300'
                          color='white'
                          style={{
                            textDecoration: 'underline',
                          }}
                        >
                          No thanks
                  </Button>
              </Form>
            )}
            </Formik>
          </Step>
        )
      case 2:
        return (
          <Step 
            stepColors={stepColors[currentStep]}
            title='Thank you so much for taking the time to provide us with your feedback'
          >
            <Container
              px='0'
              mb='10'
              centerContent
              color={stepColors[currentStep].textColor} 
            >
              <Text
                align='center'
                fontStyle='normal'
                fontWeight='700'
                fontSize='20px'
                lineHeight='27px'
                color='black'
                mb={8}
              >
                Interested in a call?
              </Text>
              <Text 
                color='black'
                align='center'
              >
                Our team of product managers is always happy to hear about your positive or negative experience using this app. If you would like to speak with us, book a time now!
              </Text>
            </Container>
            <Button 
              as='a' 
              href='https://shopcircle-product.youcanbook.me/' 
              mb='4'
              rightIcon={<Icon as={MdPhone} boxSize={5} />}
              >
                Book Call
            </Button>
          </Step>
        )
      default:
        return (
          <Text>
            Something went wrong, please refresh the page.
          </Text>
        )
    }
  }

  useEffect(() => {
    const params = ['domain', 'agent', 'app_name', 'src']
    let dataCopy = { ...data }
    params.forEach((param) => {
      if(getUrlParam(param)) {
        dataCopy = {
          ...dataCopy,
          [param]: getUrlParam(param)
        }
      }
    })
    setData((prev) => ({ ...prev, ...dataCopy }))
  }, [])

  return (
    <>    
      <Box
        as='section'
        minH='100vh'  
        display='flex'
        flexDirection='column'
        justifyContent='space-between'
        flexWrap='wrap'
        bg={stepColors[currentStep].bodyBgColor}
      >  
        <Box
          backgroundImage={`url('https://firebasestorage.googleapis.com/v0/b/sc-review-portal.appspot.com/o/sc-shapes.png?alt=media&token=27fc3823-fe12-46e0-820b-e9384ae3d96d')`}
          backgroundRepeat='repeat-x'
          height='125px'
        />
        {renderStep(currentStep, stepColors)}
        <Footer bg={stepColors[currentStep].footerBgColor} />
      </Box>
    </>
  )
}

export default SteppedForm
