import React from 'react'
import {
  ChakraProvider
} from '@chakra-ui/react'
import { Helmet } from 'react-helmet'

import theme from './styles/theme'

import FeedBackSection from './components/FeedBackSection'

export const App = () => {
  return (
    <>    
      <ChakraProvider theme={theme}>
        <Helmet>
          <meta charSet='utf-8' />
          <title data-react-helmet="true">Merchant Feedback Portal | Shop Circle</title>
          <link rel='canonical' href='https://feedback.shopcircle.co/' data-react-helmet="true" />
          <link href='https://assets-global.website-files.com/62037257247083091d895174/6218eb7c92bd108fa0cf690d_Favicon.png' rel='shortcut icon' type='image/x-icon' />
          <meta name='description' content='Shop Circle Merchant Feedback Portal' data-react-helmet="true" />
        </Helmet>
        <FeedBackSection />
      </ChakraProvider>
    </>
  )
}
