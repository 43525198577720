import React, { FC } from 'react'
import { FieldHookConfig, useField } from 'formik'
import {
  Textarea as ChakraTextarea, 
  TextareaProps as ChakraTextareaProps 
} from '@chakra-ui/react'
import ErrorMessage from './Form/ErrorMessage/ErrorMessage'

type TextareaProps = ChakraTextareaProps 
  & FieldHookConfig<'input'> 
  & {
    errorMsg: string,
  }

const Textarea:FC<TextareaProps> = ({ name, errorMsg }) => {
  const [field] = useField(name)
  
  return (
    <>    
      <ChakraTextarea 
        placeholder='Type your answer here…'
        bg='white' 
        size='md'
        borderRadius='12px'
        fontSize='16px'
        lineHeight='20px'
        {...field}
        focusBorderColor='green.500'
      />
      <ErrorMessage name={name} errorMsg={errorMsg} />
    </>
  )
}

export default Textarea