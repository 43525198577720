import React, { FC } from 'react'
import { Button as CButton, ButtonProps as ChakraButtonProps } from '@chakra-ui/react'

export type ButtonProps = ChakraButtonProps & {
  href?: string,
  target?: string,
}

const Button:FC<ButtonProps>  = (props) => {
  return (
    <CButton {...props}>
      {props.children}
    </CButton>
  )
}

Button.defaultProps = {
  colorScheme: 'green',
  minWidth: '206px',
  borderRadius: '28px',
  height: '49px',
  fontSize: '20px',
  lineHeight: '27px',
}

export default Button
