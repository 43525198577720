import React, { FC } from 'react'
import { Box, Text } from '@chakra-ui/react'
import FormContainer from './FormContainer'

export interface StepProps {
  stepColors?: any,
  title?: string,
  children?: any,
}

const Step:FC<StepProps> = ({ title, children, stepColors }) => {
  return (
    <FormContainer
      mb={[10, 10, 0, 0]}
    >
      {title ? (
        <Box 
        mt={[10, 10, 10, 10]}
        mb={[10, 10, 16, 16]}
        >
          <Text
            align='center'
            fontWeight='700'
            fontSize={['24px','32px','32px']}
            lineHeight={['32px','42px','42px']}
            color={stepColors?.textColor}
          >
            {title}
          </Text>
        </Box>  
      ) : (
        null
      )}   
      {children}
    </FormContainer>
  )
}

export default Step