import React, { FC } from 'react'
import { ErrorMessage as FormikErrorMessage, ErrorMessageProps as FormikErrorMessageProps } from 'formik'
import { Text } from '@chakra-ui/react'

export interface ErrorMessageProps extends FormikErrorMessageProps {
  errorMsg: string,
  bg?: string,
  padding?: string
}

const ErrorMessage:FC<ErrorMessageProps> = ({ name, errorMsg }, props) => {
  return (
    <FormikErrorMessage name={name}>
        {() => (
        <Text
          pt='2'
          color='red.500'
          textAlign='center'
          {...props}
        >
          {errorMsg}
        </Text>)}
    </FormikErrorMessage>
  )
}

export default ErrorMessage
