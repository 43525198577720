import React, { FC } from 'react'
import { useField } from 'formik'
import {
  RadioGroup as ChakraRadioGroup,
  RadioGroupProps as ChakraRadioGroupProps,
} from '@chakra-ui/react'

type Props = ChakraRadioGroupProps & {
  name: string,
}

const RadioGroup:FC<Props> = ({ name, children, ...props }) => {
  const [field, , { setValue }] = useField({ name, value: props.value })

  const namedChildren = React.Children.map(children, (child) => {
    if (!React.isValidElement(child)) return

    return React.cloneElement(child, {
      name,
    })
  })

  return (
    <ChakraRadioGroup
      {...props}
      {...field}
      onChange={setValue}
      children={namedChildren}
    />
  )
}

export default RadioGroup