import React, { FC } from 'react'
import { HStack, Link } from '@chakra-ui/react'

export interface FooterProps {
  bg?: string
}

const Footer:FC<FooterProps> = ({ bg }) => {
  return (
    <HStack
      as='footer'
      py='8'
      mt='4'
      justifyContent='center'
      bg={bg}
    >
      <Link 
        isExternal
        color='green.500'
        style={{ textDecoration: 'underline' }} 
        href='https://shopcircle.co'
      >
        shopcircle.co
      </Link>
    </HStack>
  )
}

Footer.defaultProps = {
  bg: 'white',
}

export default Footer
