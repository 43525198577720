import React from 'react'
import { useField } from 'formik'
import {
  UseRadioProps,
  useRadio,
  ImageProps,
  chakra,
  useRadioGroupContext,
  Icon,
} from '@chakra-ui/react'

import { FaStar } from 'react-icons/fa'

type Props = UseRadioProps &
  ImageProps & {
  } & {
    name?: any,
    numberOfStars: string,
    onClick: any,
    value: any,
  }

const StarRadio = React.forwardRef((props: Props, ref) => {
  const { name, key, isDisabled, numberOfStars, onClick, ...radioProps } = props
  const group = useRadioGroupContext()

  let isChecked = group?.value?.toString() === props?.value?.toString()

  const [{ checked, ...field }] = useField({
    name,
    type: "radio",
    value: radioProps?.value?.toString(),
    checked: isChecked,
  })

  const { getInputProps, htmlProps } =
    useRadio({
      isChecked: isChecked,
      isDisabled: isDisabled,
      ...field,
    })

  return (
    <chakra.label {...htmlProps} cursor="pointer">
      <input {...getInputProps({}, ref)} hidden />
  
      <Icon 
        as={FaStar}
        boxSize='8' 
        color={numberOfStars >= props?.value?.toString() ? "green.500" : '#E5E5E5'}
        onClick={onClick}
      />
    </chakra.label>
  )
})

export default StarRadio