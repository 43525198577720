import React, { FC } from 'react'
import { 
  FormLabel as ChakraFormLabel,
  FormLabelProps as ChakraFormLabelProps,
} from '@chakra-ui/react'

interface FormLabelProps extends ChakraFormLabelProps {}

const FormLabel:FC<FormLabelProps> = (props) => {
  return (
    <ChakraFormLabel 
      fontWeight='400'
      fontSize={['16px','20px','20px']}
      lineHeight={['22px','27px','27px']}
      marginBottom={[5]}
      mx='0'
      {...props}
    >

    </ChakraFormLabel>
  )
}

export default FormLabel
